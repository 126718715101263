<template>
  <div>
    <h2 v-html="$t('search_milk_quality_value_title')"></h2>
    <!-- ≥ Keime (Anzahl) -->
    <v-text-field
      id="search_milk_quality_value_germs"
      v-model="SearchMilkQualityValueStore.Quality_Germs"
      :label="$t('search_milk_quality_value_germs')"
      type="number"
      @keyup.enter="search"
    />
    <!-- ≥ Zellen (Anzahl) -->
    <v-text-field
      id="search_milk_quality_value_cells"
      v-model="SearchMilkQualityValueStore.Quality_Cells"
      :label="$t('search_milk_quality_value_cells')"
      type="number"
      @keyup.enter="search"
    />
    <!-- Hemmstoffe -->
    <v-select
      id="search_milk_quality_value_inibitors"
      v-model="SearchMilkQualityValueStore.Quality_Inibitor"
      :items="inhibitors"
      :item-title="this.$getLangKey()"
      item-value="name"
      :label="$t('search_milk_quality_value_inibitors')"
      clearable
      @keyup.enter="search"
    />
    <!-- ≥ Gefrierpunkt -->
    <v-text-field
      id="search_milk_quality_value_freezing_point"
      v-model="SearchMilkQualityValueStore.Quality_FreezingPoint"
      :label="$t('search_milk_quality_value_freezing_point')"
      type="number"
      @keyup.enter="search"
    />
    <!-- expanded-->
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- ≥ Freie Fettsäuren -->
      <v-text-field
        id="search_milk_quality_value_fatty_acid"
        v-model="SearchMilkQualityValueStore.Quality_FattyAcid"
        :label="$t('search_milk_quality_value_fatty_acid')"
        type="number"
        @keyup.enter="search"
      />
      <!-- ≥ Buttersäuresporen -->
      <v-text-field
        id="search_milk_quality_value_clostridia"
        v-model="SearchMilkQualityValueStore.Quality_Clostridia"
        :label="$t('search_milk_quality_value_clostridia')"
        type="number"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { app } from '../../main'
import { defineComponent } from 'vue'
import { useInhibitorsStore } from '@/store/enums/InhibitorsStore'

export default defineComponent({
  name: 'searchMilkQualityValue',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      app: app
    }
  },
  computed: {
    inhibitors() {
      return useInhibitorsStore().inhibitors
    },
    SearchMilkQualityValueStore() {
      return this.$store.state.SearchMilkQualityValueStore
    }
  },
  methods: {
    reset() {
      this.resetFields(this.SearchMilkQualityValueStore)
    }
  },
  beforeMount() {
    useInhibitorsStore().fetchInhibitors()
  },
  mounted() {
    this.initSearchStoreFromQuery(this.SearchMilkQualityValueStore, this.$route.query)
  }
})
</script>
